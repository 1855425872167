import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    grid,
    titleText,
    subtitleText,
    contentText,
    wrapper,
    imageContainer,
    noHover,
    image,
} from './manufacturer-list.module.scss';
import { ISection } from '../../models/section.model';
import { IPartnerLogotype } from '../../models/partner-logotype.model';

import Section from '../hoc/section';
import ConditionalLink from '../hoc/conditional-link';

export interface ISectionProduct extends ISection {
    items: {
        manufacturers: IPartnerLogotype[];
    };
}

interface IManufacturerListProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISectionProduct;
}

const ManufacturerList: React.FC<IManufacturerListProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const {
        sectionId,
        content: { texts, buttons },
        items: { manufacturers },
        css,
        style,
    } = section;

    if (!manufacturers || manufacturers.length === 0) return null;

    const manufacturersWithImages = manufacturers.filter((manufacturer) => !!manufacturer.media[0]);

    return (
        <Section
            sectionId={sectionId}
            className={`${container} ${className}`}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
        >
            <div className={wrapper}>
                {manufacturersWithImages.map((manufacturer) => {
                    return manufacturer.url ? (
                        <ConditionalLink
                            type={'remoteUrl'}
                            key={manufacturer.logoId}
                            to={manufacturer.url}
                            blank={true}
                            className={imageContainer}
                        >
                            <Image
                                media={manufacturer.media}
                                className={image}
                                objectFit={'contain'}
                            />
                        </ConditionalLink>
                    ) : (
                        <div key={manufacturer.logoId} className={imageContainer}>
                            <Image
                                media={manufacturer.media}
                                className={`${image} ${noHover}`}
                                objectFit={'contain'}
                            />
                        </div>
                    );
                })}
            </div>
        </Section>
    );
};

export default ManufacturerList;
