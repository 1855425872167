import React from 'react';

import { grid, title as titleClass, container } from './details.module.scss';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import DetailsCard from '../molecules/details-card';
import Markdown from '../hoc/markdown';
import Content from '../atoms/content';

export interface IDetailsProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function Details({ TitleTag, section, className = '' }: IDetailsProps) {
    const { sectionId, content, css, style } = section;
    const { texts, images } = content;
    const [title, , title1, title2, title3, title4, text1, text2, text3, text4] = texts;

    // @ts-ignore
    const [image1, image2, image3, image4] = images;

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            {title && (
                <Content className={titleClass}>
                    <Markdown>{title}</Markdown>
                </Content>
            )}
            <div className={container}>
                <DetailsCard
                    title={title1 || ''}
                    description={text1 || ''}
                    image={image1.media[0]}
                />
                <DetailsCard
                    title={title2 || ''}
                    description={text2 || ''}
                    image={image2.media[0]}
                />
                <DetailsCard
                    title={title3 || ''}
                    description={text3 || ''}
                    image={image3.media[0]}
                />
                <DetailsCard
                    title={title4 || ''}
                    description={text4 || ''}
                    image={image4.media[0]}
                />
            </div>
        </Section>
    );
}
