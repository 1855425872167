import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    contentWrapper,
    title as titleClass,
    description as descriptionClass,
    button as buttonClass,
    image,
    ratioClass,
    layout,
} from './quote-business.module.scss';
import { ISection, ISectionContentButton } from '../../models/section.model';

import Section from '../hoc/section';
import Content from '../atoms/content';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

export interface IQuoteBusinessProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function QuoteBusiness({ TitleTag, section, className = '' }: IQuoteBusinessProps) {
    const { sectionId, content, css, style } = section;
    const { texts, buttons, media } = content;

    const [title, , description] = texts;
    const { text: buttonText, url: buttonUrl }: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${layout}`}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={contentWrapper}>
                {title && (
                    <Content className={titleClass}>
                        <Markdown>{title}</Markdown>
                    </Content>
                )}
                {description && (
                    <Content className={descriptionClass}>
                        <Markdown>{description}</Markdown>
                    </Content>
                )}
                {buttonText && buttonUrl && (
                    <Button as="link" className={buttonClass} to={buttonUrl}>
                        {buttonText}
                    </Button>
                )}
            </div>
            <Image media={media} className={image} ratioClass={ratioClass} />
        </Section>
    );
}
