import React from 'react';

import {
    card,
    image as imageClass,
    title as titleClass,
    description as descriptionClass,
} from './details-card.module.scss';

import { IMedia } from '@alterpage/gatsby-source-alterpress';

import { Image } from '@alterpage/gatsby-plugin-image';
import Markdown from '../hoc/markdown';

interface IDetailsCardProps {
    className?: string;
    title: string;
    description: string;
    image: IMedia;
}

const DetailsCard: React.FC<IDetailsCardProps> = ({
    className = '',
    title,
    image,
    description,
}) => {
    return (
        <div className={`${className} ${card}`}>
            <Image className={imageClass} media={[image]} />
            <Markdown className={titleClass}>{title}</Markdown>
            <Markdown className={descriptionClass}>{description}</Markdown>
        </div>
    );
};
export default DetailsCard;
