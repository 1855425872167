import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    contentWrapper,
    title as titleClass,
    description as descriptionClass,
    button as buttonClass,
    image,
    ratioClass,
    layout,
} from './hero-section.module.scss';
import { ISection, ISectionContentButton } from '../../models/section.model';
import { dataLayerEvents } from '../../config/data-layer';

import Section from '../hoc/section';
import Content from '../atoms/content';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

export interface IHeroSectionProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function HeroSection({ TitleTag, section, className = '' }: IHeroSectionProps) {
    const { sectionId, content, css, style } = section;
    const { texts, buttons, media } = content;

    const [title, subtitle] = texts;
    const { text: buttonText, url: buttonUrl }: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${layout}`}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={contentWrapper}>
                {title && (
                    <Content className={titleClass}>
                        <Markdown>{title}</Markdown>
                    </Content>
                )}
                {subtitle && (
                    <Content className={descriptionClass}>
                        <Markdown>{subtitle}</Markdown>
                    </Content>
                )}
                {buttonText && buttonUrl && (
                    <Button
                        as="link"
                        className={buttonClass}
                        to={buttonUrl}
                        stylePreset={'instagram-cta'}
                        onClick={() => {
                            dataLayerEvents.ctaClick(
                                'mainpage hero section',
                                `${buttonText}`,
                                `${buttonUrl}`
                            );
                        }}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
            <Image media={media} className={image} ratioClass={ratioClass} />
        </Section>
    );
}
