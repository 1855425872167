import React from 'react';

import { IStory } from '../../models/story.model';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import SingleStory from '../molecules/single-story';

import {
    grid,
    title as titleClass,
    subtitle as subtitleClass,
    description as descriptionClass,
    storiesWrapper,
    layout,
} from './story-business.module.scss';
import Content from '../atoms/content';
import Markdown from '../hoc/markdown';

export interface IStoryBusinessSection extends ISection {
    content: ISection['content'] & Record<'stories', IStory[]>;
}

export interface IStoryProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IStoryBusinessSection;
}

export default function StoryBusiness({ section, className }: IStoryProps) {
    const { sectionId, content, css, style } = section;
    const { texts, stories } = content;
    const [title, subtitle, description] = texts;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${layout}`}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            {subtitle && (
                <Content className={subtitleClass}>
                    <Markdown>{subtitle}</Markdown>
                </Content>
            )}
            {title && (
                <Content className={titleClass}>
                    <Markdown>{title}</Markdown>
                </Content>
            )}
            {description && (
                <Content className={descriptionClass}>
                    <Markdown>{description}</Markdown>
                </Content>
            )}
            <div className={storiesWrapper}>
                {stories.map((story, index) => (
                    <SingleStory
                        title={story.title}
                        description={story.description}
                        media={story.media}
                        key={`story-${index}`}
                    />
                ))}
            </div>
        </Section>
    );
}
